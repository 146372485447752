import {
  Button,
  Grid,
  Container,
} from '@mui/material';
import {
  Box,
  // styled, width
} from '@mui/system';
import { useSnackbar } from 'src/components/snackbar';

import {
  useEffect,
  useRef,
  useState,
} from 'react';
import axios from 'src/utils/axios';
import { useNavigate } from 'react-router';
import Editor from 'src/components/editor/Editor';
import { useAuthContext } from 'src/auth/useAuthContext';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';

// type Signature = {
//   Signature: string;
// };

// interface SignatureDTO {
//   UserID: number;
//   Signature: string ;
//   EntryBy?: number ;
// }
interface ViewJobDialogProp {
  id: string;
  edit: boolean;
}

// interface TabPanelProps {
//   children?: React.ReactNode;
//   index: number;
//   value: number;
// }

export default function SignatureTab() {
  const isInitialLoad = useRef(true);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const [editor, setEditor] = useState('');

  useEffect(() => {
    if (isInitialLoad.current) {
      axios
        .get(`/core/getusersignbyid?Id=${user?.ID}`)
        .then((response) => {
          const value1 = response.data.Signature.replace(
            /<img[^>]*>/g,
            (match: any) => {
              // Check if the <img> tag already has the correct attributes
              if (
                !match.includes('src="https://eapublicbucket.s3.ap-southeast-2.amazonaws.com/EA+Footer.gif"') ||
                !match.includes('alt="EA Logo"') ||
                !match.includes('id="ea-logo-image"')
              ) {
                // Replace with the desired <img> tag
                return '<img src="https://eapublicbucket.s3.ap-southeast-2.amazonaws.com/EA+Footer.gif" alt="EA Logo" id="ea-logo-image" />';
              }
              // If the <img> tag is already correct, return it as is
              return match;
            }
          );
          setEditor(value1);
          isInitialLoad.current = false; // Set to false after initial load
        })
        .catch((err) => {
          console.error('Error fetching signature:', err);
        });
    }
  }, [user?.ID]);

  const onSubmit = async () => {
    try {
      const desiredImgTag =
        '<img src="https://eapublicbucket.s3.ap-southeast-2.amazonaws.com/EA+Footer.gif" alt="EA Logo" id="ea-logo-image" />';
      const updatedEditor = editor.replace(/<img[^>]*>/g, (match) => {
        if (
          !match.includes('src="https://eapublicbucket.s3.ap-southeast-2.amazonaws.com/EA+Footer.gif"') ||
          !match.includes('alt="EA Logo"') ||
          !match.includes('id="ea-logo-image"')
        ) {
          return desiredImgTag;
        }
        return match;
      });

      const payload = {
        UserID: parseInt(user?.ID, 10),
        Signature: updatedEditor,
        EntryBy: parseInt(user?.ID as string, 10),
      };
      console.log('Payload for manager signature = ', payload);
      axios
        .put(`/core/updateusersign`, payload)
        .then((response) => {
          enqueueSnackbar(response.data, { variant: 'success' });
        })
        .catch((error) => {
          enqueueSnackbar(error, { variant: 'error' });
        });
    } catch (error) {
      console.error(error);
    }
  };
  const back = () => {
    navigate('/profile');
  };

  const onValueChanges = (value: any) => {
    if (!isInitialLoad.current) {
      const value1 = value.replace(/<img[^>]*>/g, (match: any) => {
        if (
          !match.includes('src="https://eapublicbucket.s3.ap-southeast-2.amazonaws.com/EA+Footer.gif"') ||
          !match.includes('alt="EA Logo"') ||
          !match.includes('id="ea-logo-image"')
        ) {
          return '<img src="https://eapublicbucket.s3.ap-southeast-2.amazonaws.com/EA+Footer.gif" alt="EA Logo" id="ea-logo-image" />';
        }
        return match;
      });
      setEditor(value1);
    }
  };

  return (
    <Container sx={{ marginTop: '40px' }}>
      <CustomBreadcrumbs
        heading="Add Signature"
        links={[
          {
            name: 'Profile',
            href: '/profile',
          },
          { name: 'Add Signature' },
        ]}
      />
      <Grid container display="flow">
        <Grid container spacing={2} md={12} xl={12}>
          <Grid item xs={12} md={12} sx={{}}>
            <Box rowGap={3} columnGap={5} display="grid">
              <Editor value={editor} onChange={onValueChanges} onchan={setEditor} simple />
            </Box>
            <Grid container justifyContent="space-between" sx={{ marginTop: '20px' }}>
              <Grid item>&nbsp;</Grid>
              <Grid item>
                <Button variant="contained" onClick={onSubmit} color="primary">
                  Update
                </Button>
                <Button
                  variant="contained"
                  onClick={back}
                  sx={{ marginLeft: '10px' }}
                  color="secondary"
                >
                  Back
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

// const publicDocuments = [
//   { fileName: 'public_report', fileType: 'pdf' },
//   { fileName: 'public_presentation', fileType: 'pptx' },
//   { fileName: 'public_budget', fileType: 'xlsx' },
//   { fileName: 'public_policy', fileType: 'docx' },
// ];